<template>
  <div class="subscriptions-page">
    <subscriptions :homePage="false"></subscriptions>
  </div>
</template>

<script>
import subscriptions from '@/components/subscriptions.vue'

export default {
  components: {
    subscriptions,
  },
}
</script>

<style scoped lang="scss">
.subscriptions-page {
  margin: 5px 0;
}
</style>
